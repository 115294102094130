import 'react-toastify/dist/ReactToastify.css';
import '../theme/template.scss'; // load after react toastify to override variables

import { ReactNode, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { ToastContainer } from 'react-toastify';

import { graphql, Link, navigate, Slice, useStaticQuery } from 'gatsby';

import {
  CUSTOM_NAVIGATION_EVENT,
  CustomNavigationEvent,
} from '../utils/custom-navigation-event';
import Footer from './footer/footer';

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  const query = useStaticQuery<Queries.LayoutQuery>(graphql`
    query Layout {
      site {
        siteMetadata {
          title
        }
      }

      footermenu: simpelWpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
        menuItems {
          nodes {
            id
            label
            url
            connectedNode {
              node {
                uri
              }
            }
          }
        }
      }
      simpelWp {
        privacy
      }
    }
  `);

  useEffect(() => {
    const handleNavigation = (event: CustomEvent<CustomNavigationEvent>) => {
      // special case to always scroll to top
      if (event.detail.anchor === '/#willkommen') {
        navigate('/');
      } else {
        setTimeout(() => navigate(event.detail.anchor), 150);
      }
    };

    document.addEventListener(CUSTOM_NAVIGATION_EVENT, handleNavigation);

    // unsubscribe on unmount
    return () =>
      document.removeEventListener(CUSTOM_NAVIGATION_EVENT, handleNavigation);
  }, []);

  // const footerMenuItemsNodes = query.footermenu?.menuItems.nodes || [];
  const { privacy } = query.simpelWp;

  return (
    <>
      <div
        className="mt-[69px] flex min-h-[calc(100vh-270px)] flex-col lg:mt-[114px]"
        id="page-content"
      >
        <Slice alias="header" />
        <main>{children}</main>
        <Footer />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          closeButton={false}
          closeOnClick
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* <Slice alias="footer" /> */}
      </div>
      <CookieConsent
        enableDeclineButton
        cookieName="FRAUENARZT_MEMMINGEN_ANALYTICS_CONSENT"
        containerClasses="!bg-light-black !px-6 !text-center md:!text-left !flex !justify-center"
        buttonText="Verstanden"
        buttonClasses="!bg-white !text-black !border !border-solid !border-gray-300 hover:!bg-gray-100 focus:!ring-4 focus:!ring-blue-300 !font-medium !rounded-lg !text-sm !px-5 !py-2.5 !text-center !mr-3 !mb-3 !tracking-wider"
        declineButtonText="Ablehnen"
        declineButtonClasses="!bg-transparent !text-white !border !border-solid !border-transparent hover:!border-white focus:!ring-4 focus:!ring-blue-300 !font-medium !rounded-lg !text-sm !px-5 !py-2.5 !text-center !mr-3 !mb-3"
        ariaAcceptLabel="Akzeptiere Cookies"
        ariaDeclineLabel="Lehne Cookies ab"
      >
        <span>
          Wir nutzen Cookies und Analytics um unsere Seite zu optimieren
        </span>
        {privacy && (
          <span>
            :&nbsp;
            <Link
              to={`/${privacy}`}
              // className="hover:text-red active:text-red cursor-pointer text-white underline"
            >
              Datenschutzerklärung
            </Link>
          </span>
        )}
      </CookieConsent>
    </>
  );
};

export default Layout;
